import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import SEO from '../../seo';
import CnpjMaskCustom from '../../MaskInput/cnpj';
import CpfMaskCustom from '../../MaskInput/cpf';
import PhoneMaskCustom from '../../MaskInput/phone';
import CelularMaskCustom from '../../MaskInput/celular';
import CepMaskCustom from '../../MaskInput/cep';

import api from '../../../services/api';
import cepApi from '../../../services/cep';

import { View, LoginView } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 300,
  },
}));

function getSteps() {
  return ['Preencha o formulário de cadastro', 'Adicionando Informações', 'Final'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'one';
    case 1:
      return 'two';
    case 2:
      return 'tree';
    default:
      return 'finaly';
  }
}

export default function Ong() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [radio, setRadio] = useState('cnpj');
  const steps = getSteps();
  if (profile) {
    navigate('app/editar');
  }
  const [form, setForm] = useState({
    email: '',
    name: '',
    password: '',
    repeatPassword: '',
  });
  const [profileForm, setProfileForm] = useState({
    cpf_cnpj: '',
    razao: '',
    fone: '',
    celular: '',
    cep: '',
    end: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCnpj = (event) => {
    setRadio(event.target.value);
  }

  async function handleRegister(e) {

    const { name, email, password, repeatPassword } = form;

    if (e === 'verifica') {
      try {
        setLoading(true);
        const { data } = await api.post(`users/verifica`, {
          email,
          password,
          repeatPassword,
        });

        if (data.error) {
          toast.warning(data.error);
          setLoading(false);
          return;
        }

        handleNext();
        setLoading(false);
        return;
      } catch (err) {
        toast.error(err);
        return;
      }
    }

    if (e === 'profile') {
      setLoading(true);
      const { data } = await api.get(`verifica/profile?get=true`, {
        params: {
          cpf_cnpj: profileForm.cpf_cnpj.replace(/[^\d]+/g, ''),
          razao: profileForm.razao,
          celular: profileForm.celular,
          cep: profileForm.cep,
          end: profileForm.end,
          bairro: profileForm.bairro,
          cidade: profileForm.cidade,
          estado: profileForm.estado,
        }
      });

      if (data.error) {
        toast.warning(data.error);
        setLoading(false);
        return;
      }

      setLoading(false);
      handleNext();
      return;
    }

    setLoading(true);
    try {
      const { data } = await api.post('users?ong=true', {
        name,
        email,
        password,
        repeatPassword
      });

      if (data.error) {
        toast.error(data.error);
        setLoading(false);
        return;
      }

      await api.post(`ong/profile?userid=${data.id}`, {
        cpf_cnpj: profileForm.cpf_cnpj.replace(/[^\d]+/g, ''),
        razao: profileForm.razao,
        celular: profileForm.celular,
        cep: profileForm.cep,
        end: profileForm.end,
        bairro: profileForm.bairro,
        cidade: profileForm.cidade,
        estado: profileForm.estado,
      });

      toast.success('Login criado com sucesso.');
      setLoading(false);
      handleNext();
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
      setLoading(false);
    }
  }

  async function handleCep(event) {
    const count = event.replace(/[^\d]+/g, '');
    if (count.length <= 7) {
      return;
    }

    const { data } = await cepApi.get(`${count}/json/`);

    if (data.erro) {
      toast.error('Ops! O cep informado está errado.');
      return;
    }

    setProfileForm({
      ...profileForm,
      cep: data.cep,
      end: data.logradouro,
      bairro: data.bairro,
      cidade: data.localidade,
      estado: data.uf,
    });
  };

  return (
    <>
      <SEO title="Cadastro" />
      <View>
        <LoginView>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          {activeStep === steps.length ? (
            <div className="bloco">
              <div className="btn">
                <h4>Obrigado {form.name}!</h4>
                <p>Aguarde sua aprovação pelo administrador, link para login abaixo.</p>
              </div>
              <Button onClick={() => navigate('login')} variant="contained" color="primary">Ir para o Login</Button>
            </div>
          ) : (
            <div>
              {getStepContent(activeStep) === 'one' && (
                <form>
                <h3 style={{ marginTop: 14 }}>CADASTRAMENTO DE ONG</h3>

                <TextField
                  id="outlined-basic"
                  label="*Email"
                  variant="outlined"
                  name="email"
                  value={form.email}
                  onChange={e => setForm({ ...form, email: e.target.value })}
                  InputLabelProps={{
                    shrink: !!form.email,
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="*Nome"
                  variant="outlined"
                  name="name"
                  value={form.name}
                  onChange={e => setForm({ ...form, name: e.target.value })}
                  InputLabelProps={{
                    shrink: !!form.name,
                  }}
                />

                <TextField
                  id="outlined-basic"
                  type="password"
                  label="*Senha"
                  variant="outlined"
                  name="password"
                  value={form.password}
                  onChange={e => setForm({ ...form, password: e.target.value })}
                  InputLabelProps={{
                    shrink: !!form.password,
                  }}
                />

                <TextField
                  id="outlined-basic"
                  type="password"
                  label="*Repete a senha"
                  variant="outlined"
                  name="repeatPassword"
                  value={form.repeatPassword}
                  onChange={e => setForm({ ...form, repeatPassword: e.target.value })}
                  InputLabelProps={{
                    shrink: !!form.repeatPassword,
                  }}
                />

              </form>
              )}

              {getStepContent(activeStep) === 'two' && (
                <form>
                <h3 style={{ marginTop: 14 }}>ADICIONANDO INFORMAÇÕES</h3>

                <div className="radio">
                <FormControl component="fieldset">
                  <RadioGroup row name="radio" value={radio} onChange={handleCnpj}>
                    <FormControlLabel value="cnpj" control={<Radio color="primary" />} label="Cnpj" />
                    <FormControlLabel value="cpf" control={<Radio color="primary" />} label="Cpf" />
                  </RadioGroup>
                </FormControl>
                </div>

                <TextField
                  id="outlined-basic"
                  label={radio === 'cnpj' ? '*CNPJ' : '*CPF'}
                  variant="outlined"
                  name="cnpj"
                  onChange={e => setProfileForm({ ...profileForm, cpf_cnpj: e.target.value })}
                  InputProps={{
                    inputComponent: radio === 'cnpj' ? CnpjMaskCustom : CpfMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: !!profileForm.cpf_cnpj,
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Nome da Ong"
                  variant="outlined"
                  name="razao"
                  value={profileForm.razao}
                  onChange={e => setProfileForm({ ...profileForm, razao: e.target.value })}
                  InputLabelProps={{
                    shrink: !!profileForm.razao,
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Telefone"
                  variant="outlined"
                  name="fone"
                  value={profileForm.fone}
                  onChange={e => setProfileForm({ ...profileForm, fone: e.target.value })}
                  InputProps={{
                    inputComponent: PhoneMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: !!profileForm.fone,
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="*Celular"
                  variant="outlined"
                  name="celular"
                  value={profileForm.celular}
                  onChange={e => setProfileForm({ ...profileForm, celular: e.target.value })}
                  InputProps={{
                    inputComponent: CelularMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: !!profileForm.celular,
                  }}
                />

                <div className="form">
                  <TextField
                    id="outlined-basic"
                    label="*Cep"
                    variant="outlined"
                    name="cep"
                    value={profileForm.cep}
                    onChange={e => {
                      handleCep(e.target.value);
                      setProfileForm({ ...profileForm, cep: e.target.value });
                    }}
                    InputProps={{
                      inputComponent: CepMaskCustom,
                    }}
                    InputLabelProps={{
                      shrink: !!profileForm.cep,
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="*Endereço"
                    variant="outlined"
                    name="end"
                    value={profileForm.end}
                    onChange={e => setProfileForm({ ...profileForm, end: e.target.value })}
                    InputLabelProps={{
                      shrink: !!profileForm.end,
                    }}
                  />
                </div>

                <div className="form">
                  <TextField
                    id="outlined-basic"
                    type="number"
                    label="Número"
                    variant="outlined"
                    name="numero"
                    value={profileForm.numero}
                    onChange={e => setProfileForm({ ...profileForm, numero: e.target.value })}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Complemento"
                    variant="outlined"
                    name="complemento"
                    value={profileForm.complemento}
                    onChange={e => setProfileForm({ ...profileForm, complemento: e.target.value })}
                  />

                  <TextField
                    id="outlined-basic"
                    label="*Bairro"
                    variant="outlined"
                    name="bairro"
                    value={profileForm.bairro}
                    onChange={e => setProfileForm({ ...profileForm, bairro: e.target.value })}
                    InputLabelProps={{
                      shrink: !!profileForm.bairro,
                    }}
                  />
                </div>

                <div className="form">
                  <TextField
                    id="outlined-basic"
                    label="*Cidade"
                    variant="outlined"
                    name="cidade"
                    value={profileForm.cidade}
                    onChange={e => setProfileForm({ ...profileForm, cidade: e.target.value })}
                    InputLabelProps={{
                      shrink: !!profileForm.cidade,
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="*Estado"
                    variant="outlined"
                    name="estado"
                    value={profileForm.estado}
                    onChange={e => setProfileForm({ ...profileForm, estado: e.target.value })}
                    InputLabelProps={{
                      shrink: !!profileForm.estado,
                    }}
                  />
                </div>

              </form>
              )}

              {getStepContent(activeStep) === 'tree' && (
                <div className="bloco">
                  <h3 style={{ marginTop: 14 }}>CONFIRMAÇÃO DE DADOS</h3>

                  <div className="conteudo-form">
                  <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell><h3>Cadastro usuário</h3></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Nome:</strong> {form.name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Email:</strong> {form.email}
                            </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell><h3>Informações Ong</h3></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>CNPJ:</strong> {profileForm.cpf_cnpj}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Nome Ong:</strong> {profileForm.razao}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Fone:</strong> {profileForm.fone}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Celular:</strong> {profileForm.celular}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Cep:</strong> {profileForm.cep}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Endereço:</strong> {profileForm.end}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Numero:</strong> {profileForm.numero}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Complemento:</strong> {profileForm.complemento}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Bairro:</strong> {profileForm.bairro}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Cidade:</strong> {profileForm.cidade}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                              <strong>Estado:</strong> {profileForm.estado}
                            </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </div>
                </div>
              )}

              <div className="btnNext">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  color="secondary"
                  className={classes.backButton}
                >
                  Voltar
                </Button>
                {activeStep === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRegister('verifica')}
                  >
                    {loading ? 'Carregando...' : 'Salvar e Avançar'}
                  </Button>
                )}
                {activeStep === 1 && (
                  <Button variant="contained" color="primary" onClick={() => handleRegister('profile')}>
                    {loading ? 'Carregando...' : 'Salvar e Avançar'}
                  </Button>
                )}
                {getStepContent(activeStep) === 'tree' && (
                  <Button variant="contained" color="primary" onClick={handleRegister}>
                    {loading ? 'Carregando...' : 'Cadastrar'}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        </LoginView>
      </View>
    </>
  );
}
