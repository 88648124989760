import React from 'react';

import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

function CustomMaskPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask="(99) 9999-9999"
    />
  );
}

CustomMaskPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default CustomMaskPhone;
